.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
}

.footer__content__container{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 25px 0;
}

.footer__start{
    flex-grow: 0;
    /* background-color: salmon; */
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
}

.footer__logo__img{
    width: 50px;
    height: auto;
}

.footer__title{
    font-size: 1rem;
    text-align: start;
    padding-left: 5px;
}

.nav__wrapper{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* background-color: darkgreen; */
    padding-left: 0;
}

.nav__item{
    list-style:none;
    padding-left:0;
}

.footer__link{
    text-decoration: none;
    color: #fff;
    padding-left: 0px;
}

.social__icon{
    width: 25px;
    padding: 0 15px;
}

.socialIcon__container{
    flex-grow: 0;
    display: flex;
}

.footer__copyright{
    color: #fff;
    font-size: 0.75rem;
    font-weight: 200;
    margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
    .social__icon{
        margin: 0 20px;
        margin-top: 5%;
    }
    .social__icon{
        padding: 10px 15px;
    }
    .socialIcon__container{
        flex-direction: column;
    }
}


@media only screen and (max-width: 480px){
    .footer__content__container{
        flex-direction: column;
    }
    .nav__wrapper{
        width: 100%;
        margin: 10% 0;
    }
    .socialIcon__container{
        flex-direction: row;
    }
}
