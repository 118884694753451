.pastEvents{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eventBox__wrapper{
    margin: 10% 0;
    text-decoration: none;
    color: #000;
}

@media only screen and (max-width: 480px){
    .eventBox__wrapper{
        margin: 20% 0;
    }
}
