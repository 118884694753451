.event__hero{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
}

.event__hero>p{
    width: 30%;
    font-size: 1.3rem;
    flex-wrap: wrap;
}

.hero__subheader{
    width: 70%;
    font-family: 'Ubuntu Mono', monospace;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    text-align: center;
}

.icon__container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 5%;
}

.icon__wrapper{
    display: flex;
    align-items: center;
    margin: 20px 10px;
}

.event__icon{
    width: 40px;
    height: auto;
}

.event__icon__title{
    font-family: 'Ubuntu Mono', monospace;
    letter-spacing: 5px;
    font-size: 0.9rem;
    padding-left: 10px;
}

.event__wrapper{
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Ubuntu Mono', monospace;
}

.event__container{
    width: 40%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
}

.event__guest__img{
    height: 35vw;
    min-height: 250px;
    max-height: 400px;
    border-radius: 20px;
}

.event__container>h2{
    font-size: 2rem;
}

.event__container>p{
    padding: 5% 0;
    margin-top: 10%;
    width: 100%;
    font-size: 1rem;
}

small{
    font-size: 0.8rem;
}

.event__main__img{
    position: inherit;
    width: 130%;
    height: auto;
}

/* carousel */

.carousel__wrapper{
    width: 100%;
    padding: 3% 0;
    margin: 3% 0;
    background-color: rgb(32, 32, 32);
}

.carousel__container {
    cursor: grab;
    overflow: hidden;
    margin: 5% 5%;
}

.carousel {
    display: flex;
}

.item {
    position: relative;
    min-height: 30rem;
    min-width: 20rem;
    padding: 20px;
}

.item img {
    width: 100%;
    max-width: 300px;
    height: 100%;
    border-radius: 20px;
    pointer-events: none;
    object-position: center;
    object-fit: cover;
}

/* End Carousel */

@media screen and (max-width: 768px) {
    .event__hero>p{
        width: 70%;
        font-size: 1.1rem;
        flex-wrap: wrap;
    }
    .event__main__img{
        width: 120%;
    }
    .hero__subheader{
        width: 80%;
    }
    .carousel__container {
        margin: 5% 0%;
    }
    .item {
        padding: 0px;
        min-height: 25rem;
    }
}

@media screen and (max-width: 480px) {
    .hero__subheader{
        width: 90%;
    }
    .event__icon{
        width: 30px;
        height: auto;
    }
    .event__icon__title{
        letter-spacing: 2px;
        font-size: 0.8rem;
    }
    .event__wrapper{
        flex-direction: column;
    }
    .event__container{
        width: 80%;
        margin: 10% 0;
    }
    .event__main__img{
        margin-top: -20%;
    }
    .item {
        min-height: 20rem;
    }
}