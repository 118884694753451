.header{
    position: relative;
    background-color: #000;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
}

.header__navbar{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 25px 50px;
}

.header__navbar__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.header__logo{
    width: 50px;
}

.header__links{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

#hideIcon{
    display: none;
}

#displayIcon{
    color: white;
    display: flex;
}

.header__icon >button{
    background-color: transparent;
    border: transparent;
}

.header__menu__inactive{
    display: none;
}

.header__menu__active{
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0% 0;
}

.menu__item{
    margin: 5% auto;
    text-align: center;
}

.menu__item:hover{
    border-bottom: 3px solid red;
    transition: ease-in 0.5s;
}

.menu__item:active { 
    border-bottom: 5px solid blue;
}

.header__links{
    text-align: center;
    color: #fff;
    text-decoration: none;
    letter-spacing: 2px;
    font-family: 'Ubuntu Mono', monospace;
    font-size: 2.5rem;
    font-weight: 400;
}

button.learn-more .header__button__text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1rem 0;
    margin: 0 0 0 1.85rem;
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
}
@media screen and (max-width: 768px) {
    .header__navbar__top{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .header__links{
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        padding: 5% 0;
    }
}

@media screen and (max-width: 480px){
    .header__links{
        font-size: 1.5rem;
        font-weight: 300;
    }
    .header__menu__active{
        padding: 0% 0;
        justify-content: space-evenly;
        height: 90vh;
    }
}
