.subHeroImage{
    width: 70vw;
    height: 35em;
    object-fit: cover;
    object-position: center;
    border-radius: 25px;
    box-shadow:  18px 18px 36px #a5a5a5,
             -18px -18px 36px #ffffff;
}

.hero__banner{
    width: 100%;
    margin: 55px 0;
    padding: 4% 0;
    background-color: #F5D059;
    color: #000;
    font-family: 'Ubuntu Mono', monospace;
    text-align: center;
}

.hero__banner>p{
    margin: auto;
    font-size: 1.3rem;
    width: 60%;
}
.hero__banner>h2{
    font-size: 2.5rem;
    margin-bottom: 20px;
    padding-top: 3%;
    letter-spacing: 0.1em;
}

button.learn-more .home__button__text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1rem 0;
    margin: 0 0 0 1.85rem;
    color: #000;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
}

button:hover .home__button__text {
    color: #fff;
}

.home__description{
    font-family: 'Ubuntu Mono', monospace;
    text-align: initial;
    font-size: 1rem;
    width: 50%;
    line-height: 33px;
    margin: 5% auto;
}

@media screen and (max-width: 768px) {
    .hero__banner>p{
        width: 90%;
        font-size: 1rem;
    }
    .subHeroImage{
        width: 90vw;
        height: 20em;
    }
    .home__description{
        width: 80%;
        text-align: center;
    }
}

@media only screen and (max-width: 480px){
    .home__description{
        width: 90%;
        margin: 25% auto;
    }
    button.learn-more .home__button__text {
        color: #fff;
    }
}
