.founder__img{
    width: 40%;
    min-width: 300px;
}

.founder__bio{
    width: 70%;
    text-align: left;
    margin: 5% auto;
}

.founder__bio>p{
    font-family: 'Ubuntu Mono', monospace;
    color: #000;
    font-weight: 400;
    font-size: 1rem;
    padding: 3%;
}

@media only screen and (max-width: 480px){
    .founder__bio{
        width: 90%;
    }
}
