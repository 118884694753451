.actionBanner{
    width: 100%;
    margin: auto;
    max-width: 1400px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
}

.banner__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 95%;
    background-image: url(../Assets/banner__image.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    border: 5px solid #F5D059;
}

.banner__container > h2{
    font-size: 2.5rem;
    margin-bottom: 20px;
    letter-spacing: 0.1em;
    color: #fff;
}

/* START BUTTON CSS */
a>button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: center;
    text-decoration: none;
    background: transparent;
    padding: 0;
}
   
   button.learn-more {
    width: 12rem;
    height: auto;
}
   
button.learn-more .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #282936;
    border-radius: 1.625rem;
    border: solid #F5D059 1px;
}
   
button.learn-more .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
}
   
button.learn-more .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
}
   
button.learn-more .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
}
   
button.learn-more .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1rem 0;
    margin: 0 0 0 1.85rem;
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
}
   
button:hover .circle {
    width: 100%;
}
   
button:hover .circle .icon.arrow {
    background: #fff;
    transform: translate(1rem, 0);
}
   
button:hover .button-text {
    color: #fff;
}
   /* END BUTTON CSS */


@media screen and (max-width: 768px) {
    .banner__container{
        width: 90%;
    }
}

@media only screen and (max-width: 480px){
    button.learn-more .circle {
        width: 100%;
    }
    .banner__container > h2{
        font-size: 2rem;
    }
}
