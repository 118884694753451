.upcoming{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero__description{
    width: 30%;
    font-size: 1rem;
    font-family: 'Ubuntu Mono', monospace;
}

.videoPlayer{
    width: 70%;
    margin-top: 5%;
}

.line{
    border-bottom: 3px solid rgba(0, 0, 0, 0.65);
    width: 20%;
    margin: 20px 0;
}

.uEvent__container{
    width: 55%;
    text-align: start;
    font-family: 'Ubuntu Mono', monospace;
    letter-spacing: 0.3rem;
    margin: 5%;
}

.uEvent__title{
    font-size: 1.5rem;
    padding: 25px 0;
}

.uEvent__description{
    font-size: 2.5rem;
}

.empty:hover{
    cursor: default;
}

@media screen and (max-width: 768px) {
    .hero__description{
        width: 50%;
    }
    .videoPlayer{
        width: 85%;
        margin-top: 10%;
    }
}

@media screen and (max-width: 480px) {
    .hero__description{
        width: 75%;
    }
    .uEvent__title{
        font-size: 1rem;
    }
    .uEvent__container{
        margin: 20% 0;
    }
    .videoPlayer{
        width: 95%;
        margin-top: 10%;
    }
}
